import React from "react"
import "./../components/layout.scss"
import Logo from '../images/twispay-logo.png'
import Shop from '../images/twispay-shop.png'
import PCILogo from '../images/pci-dss.png'
import cardsLogo from '../images/partners-horizontal-invert.svg'
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

class PayPage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      loading: false,
      payed: false,
      expiry: '',
      cardHolderName: '',
      number: '',
      date: '',
      cvv: '',
      actual: {
        name: 'Osama Jandali',
        number: '4444-4444-4444-4444',
        date: '12/22',
        cvv: '541'
      },
      items: {},
      totalCost: 0
    }
    this.pay = this.pay.bind(this)
    this.expiryChange = this.expiryChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.numberChange = this.numberChange.bind(this)
  }
  componentDidMount() {
    const allItems = this.props.data.allItemsJson.edges;
    console.log(allItems)
    let itemData = {}
    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i].node;
      itemData[item.id] = item;
    }
    const productId = localStorage.getItem('selectedItem');
    this.setState({
      items: itemData[productId],
      totalCost: Number(itemData[productId].price)
    })
  }
  pay() {
    this.setState({
      loading: true
    })
    setTimeout(() => {
      this.setState({
        loading: false,
        payed: true
      })
    }, 2000)
  }
  expiryChange(value) {
    if (value == '') {
      this.setState({
        expiry: ''
      })
    } else if ((Object.is(Number(value[value.length - 1]), NaN) && (value[value.length - 1] != '/')) || value.length > 5)
      return
    if (value.length == 2 && this.state.expiry.length == 1) {
      this.setState({
        expiry: value + '/'
      })
    } else {
      this.setState({
        expiry: value
      })
    }
  }
  numberChange(value) {
    if (value == '') {
      this.setState({
        number: ''
      })
    } else if ((Object.is(Number(value[value.length - 1]), NaN) && (value[value.length - 1] != '-')) || value.length > 19)
      return

    if ((value.length == 4 && this.state.number.length == 3) ||
      (value.length == 9 && this.state.number.length == 8) ||
      (value.length == 14 && this.state.number.length == 13)
    ) {
      this.setState({
        number: value + '-'
      })
    } else {
      this.setState({
        number: value
      })
    }
  }
  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
  }
  render() {
    return (
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
        className="pay-page"
      >
        <SEO title="Twispay" />
        <nav >
          <div className="columns is-vcentered">
            <div className="column">
              <Link to="/"> <img src={Shop} /></Link>
            </div>
            <div className="column">
              {this.state.payed ? '' :
                <p>Your total:  <strong> {this.state.items.price} {this.state.items.currency}</strong></p>
              }
            </div>
            <div className="column iconContainer">
              <div style={{ color: '#18d110', fontSize: '15pt' }} className="icon"><i className=" fas fa-lock"></i></div>
            </div>
          </div>
        </nav>
        {this.state.payed ?
          <div className="formContainer" style={{ textAlign: 'center', color: '#7176ff' }}>
            <p>Thank you for submitting your details.</p>
            <strong style={{ color: '#7176ff', margin: '20px 0', display: 'block' }}> You have successfully signed up for our contest.</strong>
            <p>Keep you posted!</p>
          </div> :
          <div className="formContainer">

            <div className="form-group card-field-group">
              <label>
                Cardholder name
        <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                name="cardHolderName"
                placeholder="Enter the name on your card"
                value={this.state.cardHolderName}
                onChange={this.handleChange}
                className={`input is-large ${this.state.cardHolderName == this.state.actual.name ? 'is-success' : ''}`} />
            </div>

            <div className="form-group card-field-group">
              <label>
                Cardholder number
        <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                name="number"
                onChange={(event) => this.numberChange(event.target.value)}
                placeholder="0000 - 0000 - 0000 - 0000"
                value={this.state.number}
                className={`input is-large ${this.state.number == this.state.actual.number ? 'is-success' : ''}`} />
            </div>
            <div className="form-group card-field-group">
              <div className="columns">
                <div className="column">
                  <label>
                    Expiry date
        <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="cardHolderName"
                    placeholder="MM    /    YY"
                    onChange={(event) => this.expiryChange(event.target.value)}
                    value={this.state.expiry}
                    className={`input is-large ${this.state.expiry == this.state.actual.date ? 'is-success' : ''}`} />
                </div>
                <div className="column">
                  <label>
                    CVV code
        <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="cvv"
                    onChange={this.handleChange}
                    placeholder="000"
                    maxLength="3"
                    className={`input is-large ${this.state.cvv == this.state.actual.cvv ? 'is-success' : ''}`} />
                </div>
              </div>
            </div>
            <a onClick={this.pay} className={`button is-large ${this.state.loading ? 'is-loading' : ''}`}>Pay</a>
            <p className="footer-form">
              <span className="fas fa-lock"></span> All card details are encrypted
      <br />
              By proceeding you accept the <a href="/terms/en" target="_blank">terms and conditions</a>
            </p>
          </div>
        }

        <footer>
          <div style={{marginBottom: 20}}><img src={cardsLogo} /></div>
          <div className="footer-icons">
            <p className="text-center" id="twispayLogo">
              <img src={Logo} />
              <img src={PCILogo} />
            </p>
          </div>
          <div className="footer-text">
            <div className="footer-descriptor">
              <p className="nomargin text-center">
                © 2019 twispay. All rights reserved
          </p>
              <p className="nomargin text-center">
                On your bank statement will appear: CFS*shop.twispay.com
          </p>
              <p className="nomargin text-center">
              Capital Financial Services SA Gara Herastrau 4C, Clădirea B, etaj 11, București România <br />
              </p>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}


export default PayPage

export const pageQuery = graphql`
query payQuery {
    allItemsJson {
        edges {
          node {
            id
            price
            currency
                   }
                  }
      }
}
`